import { useState} from 'react';

import { SectionTab } from 'polotno/side-panel';
const defaultTags = ['address1', 'address2', 'address3'];

const TagSection = ({ store }) => {
    const [_customTags, setCustomTags] = useState([]);
    const [_selectedElementId, setSelectedElementId] = useState(undefined);

    let changeTimer;
    const changeInterval = 500;
    const tagRegex = /{{([^}]+)}}/g;
    store.on('change', () => {
        var selected = store.selectedElements[0];
        if (selected === undefined) {
            setSelectedElementId(undefined);
            return;
        }

        setSelectedElementId(selected.id);
        clearTimeout(changeTimer);
        changeTimer = setTimeout(() => {
            if (_selectedElementId === undefined) {
                return;
            }

            const element = store.getElementById(_selectedElementId);
            var currentMatch;
            while ((currentMatch = tagRegex.exec(element.text))) {
                var tag = currentMatch[1];
                if (defaultTags.includes(tag) || _customTags.includes(tag)) {
                } else {
                    setCustomTags([..._customTags, tag]);
                }
            }
        }, changeInterval);
    });

    return ({
        name: 'tags',
        Tab: (props) => (
            <SectionTab name='Tags' {...props}>
                <div>&#123; &#123; &nbsp; &#125; &#125;</div>
            </SectionTab>
        ),
        Panel: ({ store }) => {
            return (
                <>
                    <p>These are your default tags</p>
                    <p>Click to add to the template</p>
                    <ul>
                        {defaultTags.map((tag) => {
                            return (
                                <li key={tag}>
                                    <button
                                        onClick={() => {
                                            var element = store.selectedElements[0];
                                            if (element === undefined) {
                                                store.activePage.addElement({
                                                    type: 'text',
                                                    text: `{{${tag}}}`,
                                                    x: 1500,
                                                    y: 400,
                                                    fontSize: 80,
                                                    width: 600,
                                                });
                                            } else {
                                                element.set({ text: `${element.text} {{${tag}}}` });
                                            }
                                        }}
                                    >
                                        {tag}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                    <p>&nbsp;</p>
                    <p>Any custom tags you add will be available here</p>
                    {_customTags.length > 0 && (
                        <ul>
                            {_customTags.map((tag) => {
                                return (
                                    <li key={tag}>
                                        <button
                                            onClick={() => {
                                                var element = store.selectedElements[0];
                                                if (element === undefined) {
                                                    store.activePage.addElement({
                                                        type: 'text',
                                                        text: `{{${tag}}}`,
                                                        x: 1500,
                                                        y: 400,
                                                        fontSize: 80,
                                                        width: 600,
                                                    });
                                                } else {
                                                    element.set({ text: `${element.text} {{${tag}}}` });
                                                }
                                            }}
                                        >
                                            {tag}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </>
            );
        }
    })
};

export default TagSection;