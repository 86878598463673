import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone'

// https://react-dropzone.js.org/

const DropZone = ({ callback, maxFiles = 1, acceptedTypes }) => {
  const [_files, setFiles] = useState([]);
  const [_errors, setErrors] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejected, ev) => {
    setFiles(acceptedFiles);

    // handle errors
    if (rejected.length > 0) {
      let errors = [];

      // handle errors
      if (acceptedFiles.length === 0) { // ignore if some accepted
        rejected.forEach(item => {
          if (errors.indexOf(item.errors[0].message) === -1) {
            errors.push(item.errors[0].message);
          }
        });
      }
      setErrors(errors);
    }
    else {
      setErrors([]);
    }

    if (callback) {
      callback(acceptedFiles[0]);
    }

  }, [callback]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    maxFiles: maxFiles,
    accept: acceptedTypes
  })
  const dragover = isDragActive ? ' is-dragover' : '';

  return (
    <div {...getRootProps({ className: 'drop-zone' + dragover })}>
      <div className='drop-zone__inner'>
        <input {...getInputProps()} />
        <div className='icon'>
          <i className='icon-upload-cloud'></i>
        </div>
        <div className='headline'>Drag & drop file{maxFiles > 1 && "s"}</div>
        <div className='browse'>or browse</div>
        {_files.length > 0 && (
          <div className="files">
            {_files.map((item, i) => (
              <div key={i} className='filename'>{item.name}</div>
            ))}
          </div>
        )}
        {_errors.length > 0 && (
          <div className="errors">
            {_errors.map((item, i) => (
              <div key={i} className='error-msg'>{item}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default DropZone;
