import { useState } from 'react';
import Spinner from '../components/Spinner.js';

const ProofPanel = ({ mailing, imageIndex, onClick }) => {
  const [showImage, setShowImage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shouldUseFallback, setShouldUseFallback] = useState(false);

  const error = () => {
    setLoading(false);
    setShouldUseFallback(true);
    setShowImage(false);
  }
    const handleClick = (id) => {
      if (onClick) {
        onClick(id)
      }
    }

    const FallBack = () => {
      return (
        <div className='pending-preview'>
          <div className='pending-preview__inner'>
            <div className='image'></div>
            <div className='msg'>
              Your preview is being created.
              <br />
              Check back soon.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'template-panel'}>
        <button className='template-panel__inner' onClick={() => handleClick('foo')}>
        <div className='template-icon'>
          <div className='image-wrap'>
            <div className='image'>
            {loading && <Spinner />}
              {showImage && <img src={mailing.proofImages[imageIndex]} width='100%' onLoad={() => setLoading(false)} onError={error} loading='lazy' />}
              {shouldUseFallback && <FallBack />}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};
export default ProofPanel;
