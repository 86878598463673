import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import TemplatePanel from './TemplatePanel.js';

// https://swiperjs.com/

const TemplateSlider = ({ template }) => {
    return (
      <div className="template-slider">
        <div className="template-slider__inner">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            className="slider"
          >
            {template.urls.map((_, i) => {
              return (
                <SwiperSlide key={i}>
                  <TemplatePanel
                    template={template}
                    colClass=""
                    imageIndex={i}
                    />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    );
}
export default TemplateSlider;
