import { useEffect, useState } from 'react';
import CardStep from '../../../components/CardStep.js';
import OptionsFormat from '../../../components/OptionsPaperSize.js';
import OptionsColor from '../../../components/OptionsColor.js';
import OptionsSides from '../../../components/OptionsSides.js';

import { content } from '../Wizard.content.js';
import { config } from '../../../Config.js';

const FormatStep = ({ stepNumber, currentStep, onSelect }) => {
    const formats = config.Formats;
    const [format, setFormat] = useState({});
    const [colour, setColour] = useState('');
    const [side, setSide] = useState('');
    const [showFormatError, setShowFormatError] = useState(false);
    const [showColourError, setShowColourError] = useState(false);
    const [showSidesError, setShowSidesError] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);

    useEffect(() => {
        setNextEnabled(validate(false));
    },[format, colour, side]);

    const selectFormat = (format) => {
        setFormat(format);
        setShowFormatError(false);
        if(format.id !== 'letter'){
            setShowSidesError(false);
        }
    }

    const selectColour = (colour) => {
        setColour(colour);
        setShowColourError(false);
    }

    const selectSide = (side) => {
        setSide(side);
        setShowSidesError(false);
    }

    const validate = (showErrors) => {
        let isValid = true;
        if(format.id == null){
            showErrors && setShowFormatError(true);
            isValid = false;
        }

        if(colour === ''){ 
            showErrors && setShowColourError(true);
            isValid = false;
        }

        if(format.id === 'letter' && side === ''){
            showErrors && setShowSidesError(true);
            isValid = false;
        }

        return isValid;
    }

    const next = () => {
        const valid = validate(true);
        if(valid){
            onSelect(format, colour, side);
        }
    }

    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={false}
            onNext={next}
            nextEnabled={nextEnabled}
            title={content.Format.Title}
        >
            <h4>{content.Format.Size.Heading}</h4>
            <hr />
            <OptionsFormat formats={formats} selectedFormat={format} onSelect={selectFormat} />
            {showFormatError && <p className='error-msg'>{content.Format.Size.Error}</p>}

            <h4>{content.Format.Colour.Heading}</h4>
            <hr />
            <OptionsColor color={colour} onColorSelect={selectColour} />
            {showColourError && <p className='error-msg'>{content.Format.Colour.Error}</p>}

            {format.id === 'letter' && <>
                <h4>{content.Format.Sides.Heading}</h4>
                <hr />
                <OptionsSides side={side} onSidesSelect={selectSide} />
                {showSidesError && <p className='error-msg'>{content.Format.Sides.Error}</p>}
            </>}
        </CardStep>
    )
};

export default FormatStep;