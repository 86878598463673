import { useState } from "react";
import CardStep from "../../../components/CardStep";
import { content } from '../content.js';

const CompleteStep = ({ stepNumber, currentStep, uploadAnother }) => {
    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={false}
            onNext={false}
            stepClass='card--template-uploaded'
        >
            <div className='content text-center'>
                <div className='icon-success'></div>
                <div className='heading'>{content.ConfirmUploadedHeading}</div>
                <div className='copy'>
                    {content.ConfirmUploadedText}
                </div>
                <div className='btn-wrap'>
                    <button className='btn btn-outline' onClick={uploadAnother}>
                        Upload another
                    </button>
                </div>
            </div>
        </CardStep>
    )
}

export default CompleteStep;