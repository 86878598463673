import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import ProofPanel from '../components/ProofPanel.js';

// https://swiperjs.com/

const ProofSlider = ({ mailing }) => {
  return (
    <div className='template-slider'>
      <div className='template-slider__inner'>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          className='slider'
        >
          {mailing.proofImages.map((item, i) => {
            return (
              <SwiperSlide key={item.index}>
                <ProofPanel mailing={mailing} imageIndex={i} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
export default ProofSlider;
