import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import TemplatePanel from '../../../components/TemplatePanel.js';

const TemplateOptionsComponent = ({ templates, template, onTemplateSelect }) => {

  return (
    <div className="options-template">
      <div className="options-template__inner">
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{ clickable: true, dynamicBullets: false }}
          slidesPerView={3}
          navigation
          className="templates-slider"
        >
          {templates.map((item, _) => {
            return (
              <SwiperSlide key={item.id}>
                <TemplatePanel
                  template={item}
                  {...item}
                  onClick={() => onTemplateSelect(item)}
                  isSelected={template?.id === item.id}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  );
}
export default TemplateOptionsComponent;
