import { useEffect, useState } from 'react';
import CardStep from '../../../components/CardStep.js';
import Spinner from '../../../components/Spinner.js';
import ModalWrap from '../../../components/ModalWrap.js';

//https://react-select.com/home
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useGetTemplateTagsV2 } from '../../../clients/TemplatesClient.js';

import { content } from '../Wizard.content.js';
// animations for multi select
const animatedComponents = makeAnimated();

const MappingStep = ({ stepNumber, currentStep, template, columns, onPrevious, onNext }) => {
    const [mappings, setMappings] = useState(new Map());
    const [shouldShowError, setShouldShowError] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(template);
    const [getTemplateTags, setGetTemplateTags] = useState(false);
    const [columnData, setColumnData] = useState(columns);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [showCustomTagModalOverride, setShowCustomTagModalOverride] = useState(false);

    const templateTagsResponse = useGetTemplateTagsV2(currentTemplate?.id, getTemplateTags);

    const formattedColumnData = columnData.map((column) => {
        return { id: column.index, value: column.index, label: column.name };
    });

    useEffect(() => {
        if (currentStep === stepNumber) {
            setCurrentTemplate(template);

            if (columnData !== columns) {
                setMappings(new Map());
                setColumnData(columns);
            }

            if (currentTemplate?.id !== template?.id) {
                setGetTemplateTags(true);
                setMappings(new Map());
            }
        }
    }, [stepNumber, currentStep, template, columns]);

    const next = (overrideCustomTagCheck) => {
        if (validate(true)) {
            let usedAllCustomTags = true;
            if (!overrideCustomTagCheck) {

                templateTagsResponse.data
                    .filter((tag) => tag.type === 'Custom')
                    .forEach((item, i) => {
                        const t = mappings.get(item.id);
                        if (t === undefined || t === '' || (Array.isArray(t) && t.length === 0)) {
                            usedAllCustomTags = false;
                        }
                    });

                setShowCustomTagModalOverride(!usedAllCustomTags);
            }

            if (usedAllCustomTags) {
                onNext(mappings);
            }
        }
    }

    const handleMapping = (selectedOption, tagId) => {
        setMappings(existing => {
            existing.set(tagId, selectedOption);
            return new Map(existing);
        });

        validate(false);
    };

    const validate = (showErrors) => {
        const minAddressCount = 3;
        let usedAddressTagsCount = 0;

        templateTagsResponse.data
            .filter((tag) => tag.type === 'Address')
            .forEach((item, i) => {
                const t = mappings.get(item.id);
                if (Array.isArray(t) && t.length > 0) {
                    usedAddressTagsCount++;
                }
            });

        const isValid = usedAddressTagsCount >= minAddressCount;
        if (showErrors) {
            setShouldShowError(!isValid);
        }
        if (isValid) {
            setShouldShowError(false);
        }
        setNextEnabled(isValid);
        return isValid;
    }

    const MappingColumn = ({ items, heading, description }) => {
        return (
            <div className='grey-box'>
                <h4>{heading}</h4>
                {description}
                <div className='form-group'>
                    {items.map((item) => {
                        return (
                            <div key={item.id} className='form-row'>
                                <label>{item.displayName}</label>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    options={formattedColumnData}
                                    value={mappings.get(item.id) ? mappings.get(item.id) : ''}
                                    onChange={(selectedOption) => handleMapping(selectedOption, item.id)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }


    return (
        <>
            <CardStep
                stepNum={stepNumber}
                currentStep={currentStep}
                onPrev={onPrevious}
                onNext={() => next(false)}
                nextEnabled={nextEnabled}
                stepClass='card--mapping'
                title={content.MapData.Title}
            >
                {templateTagsResponse.loading && <Spinner />}
                {templateTagsResponse.success && (
                    <div className='flex'>
                        <div className={templateTagsResponse.data.filter((item) => item.type === 'Custom').length > 0 ? 'col-6' : 'col'}>
                            <MappingColumn
                                items={templateTagsResponse.data.filter((item) => item.type === 'Address')}
                                heading={content.MapData.AddressTags.Heading}
                                description={content.MapData.AddressTags.Desc}
                            />
                        </div>
                        {templateTagsResponse.data.filter((item) => item.type === 'Custom').length > 0 && (
                            <div className='col-6'>
                                <MappingColumn
                                    items={templateTagsResponse.data.filter((item) => item.type === 'Custom')}
                                    heading={content.MapData.TemplateTags.Heading}
                                    description={content.MapData.TemplateTags.Desc}
                                />
                            </div>
                        )}
                    </div>
                )}
                {shouldShowError && <p className='error-msg'>{content.MapData.Error}</p>}
            </CardStep>


            <ModalWrap heading='Are you sure you want to continue?' show={showCustomTagModalOverride} onHide={() => setShowCustomTagModalOverride(false)}>
                <p>You have unmapped tags set in your template</p>
                <p>Are you sure you want to continue?</p>
                <div className='btn-wrap text-right'>
                    <button className='btn btn-outline' onClick={() => setShowCustomTagModalOverride(false)}>
                        Cancel
                    </button>
                    <button className='btn btn-solid' onClick={() => next(true)}>
                        Continue
                    </button>
                </div>
            </ModalWrap>
        </>
    )
}


export default MappingStep;