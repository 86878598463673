import { useEffect, useState } from 'react';
import Card from "../../components/Card";
import OptionsFormat from '../../components/OptionsPaperSize';
import Spinner from "../../components/Spinner";
import { config } from '../../Config';
import { useParams } from 'react-router-dom';

import { useGetLatestTemplateVersion } from '../../clients/TemplateEditorClient';

const NoPages = ({ store, onEditorReady }) => {
    const { templateId } = useParams();
    const [enable, setEnable] = useState(templateId != null);
    const loadedTemplate = useGetLatestTemplateVersion(templateId, enable);

    useEffect(() => {
        if (loadedTemplate.isSuccess) {
            onEditorReady({
                format: config.Formats.find(x => x.name === loadedTemplate.data.format),
                templateId: loadedTemplate.data.id,
                currentVersion: loadedTemplate.data.content.version
            });

            store.loadJSON(JSON.parse(loadedTemplate.data.content.content));

            setEnable(false);
        }
    }, [loadedTemplate])

    const setFormat = (store, format) => {
        switch (format.id) {
            case 'letter':
                store.setSize(2480, 3508);
                store.addPage();
                //   page.set({ background: 'http://127.0.0.1:5500/letter_bg.png' });
                break;
            case 'postcard':
                store.setSize(2480, 1748);
                store.addPage({ id: 'front' });
                store.addPage({ id: 'back' });
                store.selectPage('front');
                break;
        }

        onEditorReady({
            format,
            templateId: null,
            currentVersion: 0
        });
    }

    return (
        templateId == null ?
            <>
                <section className='gp-section gp-section--mailings'>
                    <div className='gp-section__inner'>
                        <Card>
                            <p>What sort of template do you want to create?</p>
                            <OptionsFormat formats={config.Formats} onSelect={(format) => setFormat(store, format)} />
                        </Card>
                    </div>
                </section>
            </>
            :
            <>
                {loadedTemplate.isLoading && <Spinner dark={true} />}
                {loadedTemplate.isError && <div>Error loading template</div>}
            </>
    );

};

export default NoPages;