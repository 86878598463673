import { useEffect, useState } from 'react';

import CardStep from '../../../components/CardStep.js';
import Spinner from '../../../components/Spinner.js';

import { content } from '../Wizard.content.js';

import { useCreateMailingV2 } from '../../../clients/WizardClient.js';


const CreateStep = ({ stepNumber, currentStep, wizardId, template, mailingName, formatOptions, mappings, onPrevious, onNext }) => {
    const [send, setSend] = useState(false);
    const createResponse = useCreateMailingV2(
        wizardId,
        template?.id,
        mailingName,
        formatOptions.colour?.id,
        mappings,
        formatOptions.format?.id,
        formatOptions.side?.id,
        send);

    useEffect(() => {
        if(send && createResponse.success) {
            console.log('createResponse', createResponse);
            onNext(createResponse.data.id);
        }

        if(createResponse.error !== null) {
            setSend(false);
        }        
    }, [createResponse]);

    const create = () => {
        setSend(true);
    }

    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={onPrevious}
            onNext={create}
            nextEnabled={!send}
            onNextLbl={
                <>
                    Create <i className='icon-arrow-right'></i>
                </>
            }
            title='Create your mailing'
            stepClass='card--mailing-send'
        >
            <div className='content text-center'>
                {!send && !createResponse.error && (
                    <>
                        <div className='icon'></div>
                        <div className='heading'>{content.Ready.Heading}</div>
                        <div className='copy'>{content.Ready.Content}</div>
                    </>
                )}
                {createResponse.loading && (
                    <>
                        <div className='icon'></div>
                        <div className='heading'>Creating...</div>
                        <Spinner />
                    </>
                )}
                {createResponse.error !== null && (
                    <>
                        <div className='icon'></div>
                        <div className='heading'>Something went wrong</div>
                        <p className='error-msg text-center'>There was a problem creating the mailing</p>
                    </>
                )}
            </div>
        </CardStep>
    )
}

export default CreateStep;