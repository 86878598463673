import { config } from '../Config';
import { useApiGetJson, useApiPostFormJson, useApiPostJson } from './client-hook';

const baseUri = config.Wizard.BaseUri;

export const useUploadDataV2 = (wizardId, file, send) => {
  const url = send === false ? null : `${baseUri}data/upload`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('wizardId', wizardId);

  return useApiPostFormJson(url, url === null ? null : formData, 30000);
};

export const useGetColumnsV2 = (wizardId, send) => {
  console.log('useGetColumnsV2', wizardId, send)
  const url = send === false ? null : `${baseUri}columns/${wizardId}`;
  return useApiGetJson(url, true);
};

export const useCreateMailingV2 = (wizardId, templateId, name, colour, mappings, format, side, send) => {
  const maps = [];
  console.log(mappings);
  mappings.forEach((data, key) => {
    maps.push({
      tagId: key,
      map: data.map((y) => ({
        order: data.indexOf(y),
        headerIndex: y.value,
      }))
    });
  });
  var url = send ? `${baseUri}create` : null;
  const createPayload = () => {
    return {
      id: wizardId,
      templateId,
      name,
      colour,
      format,
      side,
      mappings: maps
    };
  };

  return useApiPostJson(url, send ? createPayload() : null);
};
