import { Link } from "react-router-dom";

export const content = {
    Heading: 'Dashboard',
    Greeting: {
      SubTitle: 'Welcome to Postalzoom. Here you will find an overview of your account.',
    },
    Mailings: {
      Title: 'Your Mailings',
      Button: 'View all mailings',
      Empty: (
        <>
          You have no mailings scheduled. <Link to={'/create-mailing/'}>Create a mailing</Link> now!
        </>
      ),
      Error: 'Your mailings could not be loaded, please try again later.',
      Table: {
        Column0: 'Mailing name',
        Column1: 'Created',
        Column2: 'Status',
      },
    },
    Templates: {
      Title: 'Your Templates',
      Button: 'View all templates',
      Empty: (
        <>
          You have no templates uploaded. <Link to={'/create-template/'}>Upload a template</Link> now!
        </>
      ),
      Error: 'Templates could not be loaded, please try again later.',
      UseThisTemplateText: <>Use this template <i className='icon-plus'></i></>
    },
    Webhooks: {
      Title: 'Your Webhooks',
      Button: 'View all webhooks',
      Empty: (
        <>
          You have not set up any webhooks. <Link to={'/webhooks/'}>Add a webhook</Link>
        </>
      ),
      Error: 'Webhooks could not be loaded, please try again later.',
      Table: {
        Column0: 'Webhook Name',
        Column1: 'Url',
      },
    },
    HelpArticles: [
      {
        id: '1',
        title: 'Creating a template',
        excerpt: 'Get help with creating a new template.',
        icon: 'icon-template',
        url: 'https://docs.postalzoom.com/portal/en/kb/templates'
      },
      {
        id: '2',
        title: 'Formatting your data',
        excerpt: 'Learn how to format your data ready for importing.',
        icon: 'icon-table',
        url: 'https://docs.postalzoom.com/portal/en/kb/importing-data'
      },
      {
        id: '3',
        title: 'Sending a mailing',
        excerpt: 'Get started with sending a new mailing',
        icon: 'icon-plane',
        url: 'https://docs.postalzoom.com/portal/en/kb/mailings'
      },
    ]
  };
