const OptionsFormat = ({ formats, selectedFormat, onSelect }) => {

	return (
		<div className="options-paper-size">
			<div className="options-paper-size__inner">
				<div className="paper-sizes">
					{
						formats.map((format, i) => {
							const isSelected = (selectedFormat?.id === format.id) ? ' is-selected' : '';
							return (
								<div key={format.id} className={"paper-size paper-size--" + format.id + isSelected}>
									<button className="paper-size__inner" onClick={() => onSelect(format)}>
										<div className="icon">
											<div className="image"></div>
										</div>
										<div className="meta">
											<div className="name">{format.name}</div>
										</div>
									</button>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	);
}
export default OptionsFormat;
