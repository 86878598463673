import { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { SidePanel, TextSection, BackgroundSection, UploadSection, LayersSection, ElementsSection } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import styles from './TemplateEditorPage.module.css';

import TagSection from './TagSection';
import NoPages from './NoPages';
import ActionControls from './ActionControls';

// store.setUnit({
//   unit: 'mm',
//   dpi: 300,
// });

// const defaultTags = ['address1', 'address2', 'address3'];

// var pageType = new URLSearchParams(window.location.search).get('type');

// if (pageType === 'letter') {
//   store.setSize(2480, 3508);

//   var addressPage = store.addPage();

//   addressPage.set({ background: 'http://127.0.0.1:5500/dummy-2480x3508-Comb.jpg' });

//   addressPage.addElement({
//     type: 'svg',
//     src: '/images/300x300.svg',
//     id: 'addressBlock',
//     x: 200,
//     y: 200,
//     backgroundEnabled: true,
//     backgroundColor: 'orange',
//     backgroundOpacity: 0.5,
//     placeholder: 'Address',
//     width: 800,
//     height: 400,
//     selectable: false,
//     alwaysOnTop: true,
//     showInExport: false,
//     draggable: false,
//     contentEditable: false,
//     removable: false,
//   });
// } else {
//   store.setSize(2480, 1754);
//   var front = store.addPage();
//   var back = store.addPage();
//   back.addElement({
//     type: 'svg',
//     src: '/images/300x300.svg',
//     id: 'addressBlock',
//     x: 200,
//     y: 200,
//     backgroundEnabled: true,
//     backgroundColor: 'orange',
//     backgroundOpacity: 0.5,
//     placeholder: 'Address',
//     width: 800,
//     height: 400,
//     selectable: false,
//     alwaysOnTop: true,
//     showInExport: false,
//     draggable: false,
//     contentEditable: false,
//     removable: false,
//   });
// }

const TemplateEditorPage = ({ store }) => {
  const [editorReadyData, setEditorReadyData] = useState(null);
  const onEditorReady = (templateData) => {
    setEditorReadyData(templateData);
  }

  useEffect(() => {
    store.clear();
  }, []);

  const CustomPageControls = ({ store }) => {
    //https://polotno.com/docs/demo-page-name
    return null;
  }

  return (
    <>
      <PageHeader pageTitle='Template Editor' />
      <section className={styles.full}>
        <PolotnoContainer store={store}>
          <SidePanelWrap store={store}>
            <SidePanel
              store={store}
              sections={[TextSection, BackgroundSection, ElementsSection, UploadSection, LayersSection, TagSection({ store })]}
            />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} components={{ ActionControls: () => ActionControls({ store, editorReadyData }) }} />
            <Workspace store={store} pageControlsEnabled={true} components={{
              NoPages: () => NoPages({ store, onEditorReady }),
              PageControls: CustomPageControls,
            }} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </section>
    </>
  );
};

export default TemplateEditorPage;
