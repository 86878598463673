import { useEffect, useState } from 'react';
import CardStep from '../../../components/CardStep.js';
import Spinner from '../../../components/Spinner.js';
import DropZone from '../../../components/DropZone.js';

import { useUploadDataV2 } from '../../../clients/WizardClient.js';

import { content } from '../Wizard.content.js';

const DataStep = ({ stepNumber, currentStep, wizardId, onPrevious, onNext }) => {
    const [fileToUpload, setFileToUpload] = useState(null);
    const [addressCount, setAddressCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [showError, setShowError] = useState(false);
    const [send, setSend] = useState(false);

    const acceptedTypes = {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.xsl'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xslx']
    };

    // TODO: sort out error message not clearing

    const uploadResponse = useUploadDataV2(wizardId, fileToUpload, send);

    useEffect(() => {
        if (uploadResponse.success) {
            setAddressCount(uploadResponse.data.itemCount);
            setColumns(uploadResponse.data.columns);
        } else if (uploadResponse.error !== null) {
            setFileToUpload(null);
            setShowError(true);
        }

        setSend(false);
    }, [uploadResponse]);

    const onClearFile = () => {
        setFileToUpload(null);
        setAddressCount(0);
        setShowError(false);
    };

    const uploadData = (file) => {
        if (file === undefined) {
            return;
        }

        setFileToUpload(file);
        setSend(true);
    };

    const next = () => {
        if (addressCount > 0) {
            onNext(addressCount, columns);
        } else {
            setShowError(true);
        }
    };

    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={onPrevious}
            onNext={next}
            nextEnabled={addressCount > 0}
            stepClass='card--tabs'
            title={content.AddData.Title}
        >
            {uploadResponse.loading && <Spinner />}

            {fileToUpload === null && (
                <>
                    <DropZone callback={uploadData} acceptedTypes={acceptedTypes} />
                    {content.AddData.DownloadMessage}
                </>
            )}

            {!uploadResponse.loading && uploadResponse.success && fileToUpload !== null && (
                <>
                    <p className='text-center'>
                        File uploaded: <strong>{fileToUpload?.name}</strong>
                    </p>
                    <p className='text-center'>
                        Number of addresses in file: <strong>{addressCount}</strong>
                    </p>
                    <p className='text-center'>
                        <button className='link' onClick={onClearFile}>
                            Upload a different file
                        </button>
                    </p>
                </>
            )}

            {showError && <p className='error-msg text-center'>{content.AddData.Error}</p>}
            {uploadResponse.error !== null && (
                <p className='error-msg text-center'>
                    {uploadResponse.error.message != null ? uploadResponse.error.message : 'Something went wrong'}
                </p>
            )}
        </CardStep>
    );
}

export default DataStep;