import { useState } from 'react';

import Card from '../../components/Card.js';
import PageHeader from '../../components/PageHeader.js';
import TemplateSlider from '../../components/TemplateSlider.js';

import { content } from './Wizard.content.js';
import FormatStep from './Steps/FormatStep.js';
import TemplateStep from './Steps/TemplateStep.js';
import DataStep from './Steps/DataStep.js';
import MappingStep from './Steps/MappingStep.js';
import CreateStep from './Steps/CreateStep.js';
import CompleteStep from './Steps/CompleteStep.js';
import PricingComponent from './PricingComponent.js';
import NameComponent from './NameComponent.js';


const WizardPage = () => {
  const createId = () => Math.random().toString(36).substring(2, 9);
  const [wizardId, setWizardId] = useState(createId());
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState(null);
  const [formatOptions, setFormatOptions] = useState({ format: null, colour: null, sides: null });
  const [template, setTemplate] = useState(null);
  const [mappings, setMappings] = useState([]);
  const [addressCount, setAddressCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [mailingId, setMailingId] = useState(null);

  const prevStep = (step) => {
    setCurrentStep(step - 1);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const doReset = () => {
    setWizardId(createId());
    setCurrentStep(1);
    setName(null);
    setFormatOptions({ format: null, colour: null, sides: null });
    setTemplate(null);
    setMappings([]);
    setAddressCount(0);
    setColumns([]);
    setMailingId(null);
  };

  const onNameChanged = (name) => {
    console.log('name', name);
    setName(name);
  }

  const onFormatNext = (format, colour, side) => {
    if (formatOptions.format && formatOptions.format.id !== format.id) {
      setTemplate({});
    }

    setFormatOptions({ format, colour, side });
    nextStep();
  }

  const onTemplateNext = () => {
    nextStep();
  }

  const onTemplateSelect = (template) => {
    setTemplate(template);
  };

  const onDataNext = (addressCount, columns) => {
    setAddressCount(addressCount);
    setColumns(columns);
    nextStep();
  }

  const onMappingNext = (mappings) => {
    setMappings(mappings);
    nextStep();
  };

  const onCreateNext = (mailingId) => {
    setMailingId(mailingId);
    nextStep();
  }


  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--create-a-mailing'>
        <div className='gp-section__inner'>
          <div className='flex'>
            <div className='col-6'>
              <div className='gp-section__heading'>
                <NameComponent wizardId={wizardId} onNameChanged={onNameChanged} />
              </div>

              <FormatStep
                stepNumber={1}
                currentStep={currentStep}
                onSelect={onFormatNext} />

              <TemplateStep
                stepNumber={2}
                currentStep={currentStep}
                selectedFormat={formatOptions.format}
                selectedTemplate={template}
                onPrevious={prevStep}
                onNext={onTemplateNext}
                onSelect={onTemplateSelect} />

              <DataStep
                stepNumber={3}
                currentStep={currentStep}
                wizardId={wizardId}
                onPrevious={prevStep}
                onNext={onDataNext} />

              <MappingStep
                stepNumber={4}
                currentStep={currentStep}
                template={template}
                columns={columns}
                onPrevious={prevStep}
                onNext={onMappingNext} />

              <CreateStep
                stepNumber={5}
                currentStep={currentStep}
                wizardId={wizardId}
                mailingName={name}
                template={template}
                formatOptions={formatOptions}
                mappings={mappings}
                onPrevious={prevStep}
                onNext={onCreateNext} />

              <CompleteStep
                stepNumber={6}
                currentStep={currentStep}
                mailingId={mailingId}
                createAnother={doReset} />

            </div>
            <div className='col-6'>
              <PricingComponent
                wizardId={wizardId}
                addressCount={addressCount}
                template={template}
                formatOptions={formatOptions} />

              {template != null && (
                <Card className='card--mailing-preview' title='Template'>
                  <TemplateSlider template={template} />
                </Card>
              )}
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default WizardPage;
