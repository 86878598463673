import Card from '../../components/Card.js';
import { currencyFormat } from '../../Utils.js';

import { useCalculatePriceV2 } from '../../clients/MailingsClient.js';

const PricingComponent = ({ wizardId, addressCount, template, formatOptions }) => {
    const calculatePriceResponse = useCalculatePriceV2(
        addressCount === 0 ? 1 : addressCount,
        wizardId,
        template?.pageCount,
        formatOptions.colour?.id,
        formatOptions.format?.id,
        formatOptions.side?.id
    );

    const itemPrice = calculatePriceResponse.success
        ? currencyFormat(
            calculatePriceResponse.data.lineItems
                .map((item) => {
                    return (item.quantity / (addressCount === 0 ? 1 : addressCount)) * item.itemPrice;
                })
                .reduce((a, b) => a + b)
        )
        : '---';

    const totalPrice = calculatePriceResponse.success && addressCount > 0 ? currencyFormat(calculatePriceResponse.data.exVat) : '---';
    const vat = calculatePriceResponse.success ? currencyFormat(calculatePriceResponse.data.vat) : '---';
    const showSides = formatOptions.format?.name?.toLowerCase() === 'letter';

    return (
        <>
            <Card className='card--border card--summary' title='Summary'>
                <table>
                    <thead>
                        <tr>
                            <th>Format</th>
                            <th>Colour</th>
                            {showSides && <th>Sides</th>}
                            <th>Quantity</th>
                            <th>Item price</th>
                            <th>Total price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{formatOptions.format?.name ?? '---'}</td>
                            <td>{formatOptions.colour?.name ?? '---'}</td>
                            {showSides && <td>{formatOptions.side?.name?? '---'}</td>}
                            <td>{addressCount > 0 ? addressCount : '---'}</td>
                            <td>{itemPrice}</td>
                            <td>{totalPrice}</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        </>
    )
}

export default PricingComponent;