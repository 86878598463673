import { useEffect, useRef, useState } from 'react';
import Spinner from '../components/Spinner.js';
import processingImg from '../images/processing.jpg';

const TemplatePanel = ({ template, imageIndex = 0, onClick = () => { }, isSelected = false }) => {
  const [showImage, setShowImage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shouldUseFallback, setShouldUseFallback] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(template);

  const imageRef = useRef()

  const error = () => {
    setLoading(false);
    setShouldUseFallback(true);
    setShowImage(false);
  }

  useEffect(() => {
    if (currentTemplate.id !== template.id && imageRef) {
      if (imageRef.current) {
        imageRef.current.src = '';
      }
      setShowImage(true);
      setLoading(true);
      setShouldUseFallback(false);
      setCurrentTemplate(template);
    }
  }, [template]);

  const style = `template-panel ${isSelected ? 'is-selected' : ''}`

  return (
    <div className={style}>
      <button className='template-panel__inner' onClick={() => onClick(currentTemplate.id)}>
        <div className='template-icon'>
          <div className='image-wrap'>
            <div className='image'>
              {loading && <Spinner />}
              {showImage && <img src={currentTemplate.urls[imageIndex]} width='100%' onLoad={() => setLoading(false)} onError={error} loading='lazy' ref={imageRef} />}
              {shouldUseFallback && <img src={processingImg} width='100%' />}
            </div>
          </div>
        </div>
        <div className='template-meta'>
          <div className='template-name'>{currentTemplate.name}</div>
          <div className='template-format'>{currentTemplate.format}</div>
        </div>
      </button>
    </div>
  );
};
export default TemplatePanel;
