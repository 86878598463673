import { useEffect, useState } from 'react';
import CardStep from "../../../components/CardStep";
import DropZone from "../../../components/DropZone";
import Spinner from "../../../components/Spinner";

import { content } from '../content.js';
import { config } from '../../../Config.js';
import { useUploadTemplateV2 } from '../../../clients/TemplatesClient.js';

const acceptedTypes = {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
};

if (config.Toggles.EnablePdfs) {
    acceptedTypes['application/pdf'] = ['.pdf'];
}

const getSampleDownload = (format) => {
    switch (format?.id) {
        case 'letter':
            return content.SampleDownloads.letter;
        case 'postcard':
            return content.SampleDownloads.postcard;
        default:
            return content.SampleDownloads.default;
    }
}

const UploadStep = ({ stepNumber, currentStep, name, format, onPrevious, onFileUploaded }) => {
    const [file, setFile] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tagErrors, setTagErrors] = useState([]);
    const [upload, setUpload] = useState(false);
    const [_name, setName] = useState(name);

    const uploadResponse = useUploadTemplateV2(file, _name, format?.id, upload);

    useEffect(() => {
        setName(name);
    }, [name]);

    const onFileSelected = (file) => {
        if (file === undefined) {
            return;
        } else {
            setFile(file);
            setShowError(false);
            setUpload(true);
        }
    };

    useEffect(() => {
        if (uploadResponse.status === 500) {
            setErrorMessage('There was a problem talking to the server');
            setShowError(true);
            setTagErrors([]);
        } else if (uploadResponse.status !== 0 && uploadResponse.status !== 200) {
            if (uploadResponse.error.errors != null) {
                setTagErrors(uploadResponse.error.errors);
            } else {
                setErrorMessage(uploadResponse.error.message);
                setShowError(true);
            }
        } else{
            setShowError(false);
            setTagErrors([]);
        }

        if (uploadResponse.success) {
            onFileUploaded();
        }

        setUpload(false);

    }, [uploadResponse]);

    return (<>
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={onPrevious}
            title='Upload your template'
        >
            {uploadResponse.loading ? (
                <Spinner />
            ) : (
                <>
                    <p className='text-center'>
                        {
                            config.Toggles.EnablePdfs
                                ? 'The file you upload must be a valid .pdf, .doc or .docx file.'
                                : 'The file you upload must be a valid .doc or .docx file.'
                        }
                    </p>
                    <p className='text-center'>
                        Download a{' '}
                        <a href={getSampleDownload(format)} download>
                            sample template
                        </a>{' '}
                        to see an example.
                    </p>
                    <DropZone acceptedTypes={acceptedTypes} callback={onFileSelected} />
                </>
            )}

            {showError && <p className='error-msg text-center'>{errorMessage}</p>}
            {
                tagErrors.length > 0 && (
                    <p className='error-msg text-center'>
                        The following restricted tags were found: <strong>{tagErrors.join(', ')}</strong>
                    </p>
                )
            }
        </CardStep>
    </>);
}

export default UploadStep;