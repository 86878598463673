const Spinner = ({dark = false}) => {
	const className = dark ? 'spinner dark' : 'spinner';
  return (
		<div className="loading">
			<div className={className}>
			  <div className="rect1"></div>
			  <div className="rect2"></div>
			  <div className="rect3"></div>
			  <div className="rect4"></div>
			  <div className="rect5"></div>
			</div>
		</div>
  );
}
export default Spinner;
