import { useEffect, useState, useContext } from 'react';
import Spinner from '../components/Spinner.js';
import PageHeader from '../components/PageHeader.js';
import Card from '../components/Card.js';
import ModalWrap from '../components/ModalWrap.js';
import OptionsColor from '../components/OptionsColor.js';
import OptionsPaperSize from '../components/OptionsPaperSize.js';
import OptionsSides from '../components/OptionsSides.js';
import CopyToClipboard from '../components/CopyToClipboard.js';
import { useCreateWebhookV2, useGetWebhooksV2, useDeleteWebhookV2 } from '../clients/WebhooksClient.js';
import { useGetTemplatesV2, useGetTemplateTagsV2 } from '../clients/TemplatesClient.js';

import { content } from './Webhooks.content.js';

import mainContext from '../context/context.js';
import { config } from '../Config.js';

const Webhooks = () => {
  const { notification } = useContext(mainContext);

  const perPage = 10;
  const [createModel, setCreateModel] = useState({ name: '', templateId: '', colour: {}, format: {}, side: {} });

  const [_modalShow, setModalShow] = useState(false);
  const [_modalCreateShow, setModalCreateShow] = useState(false);
  const [_modalConfirmShow, setModalConfirmShow] = useState(false);

  const [_errors, setErrors] = useState({});
  const [_WHToDelete, setWHToDelete] = useState(null);
  const [sendCreateRequest, setSendCreateRequest] = useState(false);
  const [_doDelete, setDoDelete] = useState(false);

  const [templateId, setTemplateId] = useState(null);

  const [_page, setPage] = useState(1);
  const [_loadedWebhooks, setLoadedWebhooks] = useState([]);

  const webhooksResponse = useGetWebhooksV2(_page, perPage);

  const templatesResponse = useGetTemplatesV2(1, 100, [], true);
  const templateTagsResponse = useGetTemplateTagsV2(templateId);

  const createResponse = useCreateWebhookV2(createModel.name, createModel.templateId, createModel.colour?.id, createModel.format?.id, createModel.side?.id, sendCreateRequest)
  const deleteResponse = useDeleteWebhookV2(_WHToDelete, _doDelete);

  let formats = config.Formats;

  const formattedTags = () => {
    const formattedTags = templateTagsResponse.data.map((x) => `"${x.name}": "....."`);
    const json = `[\r\n  {\r\n    ${formattedTags.reduce((x, y) => `${x},\r\n    ${y}`)}\r\n  }\r\n]`;
    return <pre>{json}</pre>;
  };
  const modalContent = templateTagsResponse.loading ? 'Loading...' : templateTagsResponse.success ? formattedTags() : '';

  const loadTemplateTags = (templateId) => {
    setTemplateId(templateId);
    setModalShow(true);
  };

  useEffect(() => {
    if (_modalShow === false) {
      setTemplateId(null);
    }
  }, [_modalShow]);

  useEffect(() => {
    if (webhooksResponse.success) {
      setSendCreateRequest(false);
      setLoadedWebhooks(existing => {
        return [...existing, ...webhooksResponse.data.data];
      });
    }
    if (webhooksResponse.error !== null) {
      setSendCreateRequest(false);
    }
  }, [webhooksResponse]);

  const loadMore = () => {
    setPage(_page + 1);
  };

  useEffect(() => {
    if (createResponse.success) {
      setLoadedWebhooks(existing => [createResponse.data, ...existing]);
      setModalCreateShow(false);
      notification({ message: content.Notification.Created, type: 'positive' });
    }
  }, [createResponse]);

  useEffect(() => {
    if (deleteResponse.success) {
      notification({ message: content.Notification.Deleted, type: 'positive' });

      setLoadedWebhooks(existing => existing.filter((item) => item.id !== _WHToDelete));

      setModalConfirmShow(false);
      setWHToDelete(null);
    }
    setDoDelete(false);
  }, [deleteResponse]);

  const createWebhook = () => {
    setSendCreateRequest(false);
    setCreateModel({ name: '', templateId: '', colour: {}, format: {}, side: {} });
    setErrors({});
    setModalCreateShow(true);
  };

  const onUpdateName = (ev) => {
    setCreateModel((x) => ({ ...x, name: ev.target.value }));
    setErrors((x) => ({ ...x, error_name: null }))
  };

  const onTemplateSelect = (ev) => {
    setCreateModel((x) => ({ ...x, templateId: ev.target.value }))
    setErrors((x) => ({ ...x, error_template: null }))
  };

  const onFormatSelect = (format) => {
    setCreateModel((x) => ({ ...x, format: format, side: format === 'letter' ? x.side : null}));
    setErrors((x) => ({ ...x, error_size: null }))
  };

  const onColorSelect = (colour) => {
    console.log(colour);
    setCreateModel((x) => ({ ...x, colour: colour }));
    setErrors((x) => ({ ...x, error_color: null }))
  };

  const onSideSelect = (side) => {
    console.log(side);
    setCreateModel((x) => ({ ...x, side: side }));
    setErrors((x) => ({ ...x, error_side: null }))
  }

  const onSaveWebhook = () => {
    let errors = {};

    if (createModel.name === '') {
      errors.error_name = {
        message: 'Please choose a name',
      };
    }

    if (createModel.templateId === null || createModel.templateId === '') {
      errors.error_template = {
        message: 'Please choose a template',
      };
    }

    if (!createModel.format.id) {
      errors.error_size = {
        message: 'Please choose a size option',
      };
    }

    if (!createModel.colour.id) {
      errors.error_color = {
        message: 'Please choose a colour option',
      };
    }

    if (createModel.format?.id === 'letter' && !createModel.side?.id) {
      errors.error_side = {
        message: 'Please choose a side option'
      };
    }

    if (Object.keys(errors).length === 0) {
      setSendCreateRequest(true);
    } else {
      setErrors(errors);
    }
  };

  const onDeleteWebhook = (wh_id) => {
    setWHToDelete(wh_id);
    setModalConfirmShow(true);
  };

  const onConfirmDelete = (e, conf) => {
    if (!conf) {
      setModalConfirmShow(false);
      setWHToDelete(null);
      setDoDelete(false);
    } else {
      setDoDelete(true);
    }
  };

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--webhooks'>
        <div className='gp-section__inner'>
          <div className='gp-section__heading'>
            <div className='flex'>
              <div className='col-6'>
                <h2>{content.Title}</h2>
              </div>
              <div className='col-6 text-right'>
                <button className='btn btn-solid btn-create-webhook' onClick={createWebhook}>
                  {content.CreateButton}
                </button>
              </div>
            </div>
          </div>

          <Card className='card--webhooks'>
            {_loadedWebhooks.length > 0 ? (
              <div className='table-responsive'>
                <table className='tbl-mailings has-border'>
                  <thead>
                    <tr>
                      <th>{content.Table.Column0}</th>
                      <th className='hide-mobile'>{content.Table.Column1}</th>
                      <th className='text-center'>{content.Table.Column2}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_loadedWebhooks.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td className='hide-mobile'>
                            <span className='wh-url'>{item.url}</span>
                            <CopyToClipboard url={item.url} />
                          </td>
                          <td className='text-center'>
                            <button className='btn btn-outline btn-pill' onClick={() => loadTemplateTags(item.templateId)}>
                              {content.ViewButton}
                            </button>
                          </td>
                          <td>
                            <button
                              className='btn btn-outline btn-action'
                              onClick={() => {
                                onDeleteWebhook(item.id);
                              }}
                            >
                              <i className='icon-trash-empty'></i>
                              <span className='visually-hidden'>Delete mailing</span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className='no-results text-center'>
                {content.Empty} <br />
                <button className='link' onClick={() => setModalCreateShow(true)}>
                  {content.EmptyButton}
                </button>
              </div>
            )}
            {webhooksResponse.loading && <Spinner />}
            {webhooksResponse.error !== null && <p className='alert-msg'>{content.Error}</p>}
          </Card>
          {webhooksResponse.data.hasMorePages ? (
            <div className='btn-wrap btn-wrap--centered'>
              <button
                className={webhooksResponse.loading ? 'btn btn-solid is-spinning' : 'btn btn-solid'}
                onClick={loadMore}
                disabled={webhooksResponse.data.loading}
              >
                {content.LoadMore}
              </button>
            </div>
          ) : null}
        </div>
      </section>

      <ModalWrap heading='Create Webhook' show={_modalCreateShow} onHide={() => setModalCreateShow(false)}>
        {!sendCreateRequest && (
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor=''>{content.Create.NameField}</label>
              <input type='text' id='' name='' onChange={onUpdateName}></input>
              {_errors.error_name && <p className='error-msg'>{_errors.error_name.message}</p>}
            </div>

            <div className='form-row wh-colour'>
              <label htmlFor=''>{content.Create.SizeField}</label>
              <OptionsPaperSize sizes={formats} paperSize={createModel.format} onSizeSelect={onFormatSelect} />
              {_errors.error_size && <p className='error-msg'>{_errors.error_size.message}</p>}
            </div>
            <div className='form-row wh-colour'>
              <label htmlFor=''>{content.Create.ColourField}</label>
              <OptionsColor color={createModel.colour} onColorSelect={onColorSelect} />
              {_errors.error_color && <p className='error-msg'>{_errors.error_color.message}</p>}
            </div>

            {createModel.format.id === 'letter' &&
              <>
                <div className='form-row wh-colour'>
                  <label htmlFor=''>{content.Create.SideField}</label>
                  <OptionsSides side={createModel.side} onSidesSelect={onSideSelect} />
                  {_errors.error_side && <p className='error-msg'>{_errors.error_side.message}</p>}
                </div>
              </>}

            <div className='form-row'>
              <label htmlFor=''>{content.Create.TemplateField}</label>
              <select onChange={onTemplateSelect}>
                <option value=''>{content.Create.TemplateSelect}</option>
                {templatesResponse.success &&
                  templatesResponse
                    .data.data
                    .filter((t) => t.format.toLowerCase() === createModel.format.id)
                    .map((template) => {
                      return (
                        <option key={template.id} value={template.id}>
                          {template.name}
                        </option>
                      );
                    })}
              </select>
              {createModel.format.id && templatesResponse.success && templatesResponse.data.data.filter((t) => t.format.toLowerCase() === createModel.format.id).length === 0 && (
                <p className='error-msg'>No templates found for that format.</p>
              )}
              {_errors.error_template && <p className='error-msg'>{_errors.error_template.message}</p>}
              {templatesResponse.error !== null && <p className='error-msg'>There was a problem retrieving the templates</p>}
            </div>
          </div>
        )}
        {createResponse.loading && <Spinner />}
        {createResponse.error !== null && <p className='error-msg text-center'>There was a problem creating the webhook</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={() => setModalCreateShow(false)}>
            Cancel
          </button>
          <button className='btn btn-solid' onClick={onSaveWebhook} disabled={sendCreateRequest}>
            Save
          </button>
        </div>
      </ModalWrap>

      <ModalWrap heading={content.View.Heading} show={_modalShow} onHide={() => setModalShow(false)}>
        {modalContent}
      </ModalWrap>

      <ModalWrap heading={content.Modal.Title} show={_modalConfirmShow} onHide={(e) => onConfirmDelete(e, false)}>
        {content.Modal.Content}
        {deleteResponse.error !== null && <p className='alert-msg'>{content.ErrorDelete}</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => onConfirmDelete(e, false)}>
            Cancel
          </button>
          <button className='btn btn-solid btn-delete' onClick={(e) => onConfirmDelete(e, true)}>
            Delete Webhook
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default Webhooks;
