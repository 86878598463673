import React from 'react';
import { createRoot } from 'react-dom/client';
import './sass/app.scss';
import './fonts/fontello/css/animation.css';
import './fonts/fontello/css/fontello.css';
import { config } from './Config';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

// disable console output in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <>
    <Auth0Provider
      domain={config.Auth0.Domain}
      clientId={config.Auth0.ClientId}
      authorizationParams={{ 
        redirect_uri: config.Auth0.RedirectUri,
        audience: config.Auth0.Audience,
      }}
    >
      <App />
    </Auth0Provider>
  </>
  // </React.StrictMode>
);
