import { useEffect, useState } from 'react';
import CardStep from '../../../components/CardStep.js';
import Spinner from '../../../components/Spinner.js';
import TemplateOptionsComponent from './TemplateOptionsComponent.js';
import { useGetTemplatesV2 } from '../../../clients/TemplatesClient.js';

import { content } from '../Wizard.content.js';

const TemplateStep = ({ stepNumber, currentStep, selectedFormat, selectedTemplate, onPrevious, onSelect, onNext }) => {
    const [send, setSend] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [template, setTemplate] = useState(selectedTemplate);
    const [format, setFormat] = useState(selectedFormat);
    const templatesResponse = useGetTemplatesV2(1, 25, format === null ? [] : [{ type: 'format', value: format.id }], send);

    useEffect(() => {
        if (template != null) {
            setNextEnabled(true);
        }
    }, [template]);

    const select = (template) => {
        setTemplate(template);
        setNextEnabled(true);
        onSelect(template);
    };

    useEffect(() => {
        const current = stepNumber === currentStep;
        if (current) {
            setFormat(selectedFormat);
            setSend(selectedFormat.id !== format?.id);
        }
    }, [currentStep, selectedFormat, selectedTemplate]);

    useEffect(() => {
        console.log(templatesResponse);
        if (templatesResponse.success) {
            setSend(false);
        }

    }, [templatesResponse]);


    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={onPrevious}
            onNext={onNext}
            nextEnabled={nextEnabled}
            // stepClass='card--templates'
            title={content.TemplateSelect.Title}
        >
            {templatesResponse.loading && <Spinner />}
            {!templatesResponse.Loading && templatesResponse.success &&
                (templatesResponse.data.data.length > 0 ? (
                    templatesResponse.data.data.filter((t) => t.format.toLowerCase() === format?.id).length !== 0 ? (
                        <TemplateOptionsComponent
                            templates={templatesResponse.data.data.filter((t) => t.format.toLowerCase() === format?.id)}
                            template={template}
                            onTemplateSelect={select}
                            isSlider={true}
                        />
                    ) : (
                        content.TemplateSelect.NoTemplatesInFormat
                    )
                ) : (
                    content.TemplateSelect.NoTemplates
                ))}

            {templatesResponse.error !== null && <p className='error-msg'>{content.TemplateSelect.Error}</p>}
        </CardStep>
    )
}

export default TemplateStep;