import CardStep from '../../../components/CardStep.js';
import { content } from '../Wizard.content.js';
import { Link } from 'react-router-dom';

const CompleteStep = ({ stepNumber, currentStep, mailingId, createAnother }) => {
    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={false}
            onNext={false}
            title=''
            stepClass='card--mailing-sent'
        >
            <div className='content text-center'>
                <div className='logo'></div>
                <div className='heading'>{content.Sent.Heading}</div>
                <div className='copy'>{content.Sent.Content}</div>
                <div className='btn-wrap'>
                    <Link to={`/mailing/${mailingId}`} className='btn btn-outline'>
                        Track the progress
                    </Link>
                    <button className='btn btn-solid' onClick={createAnother}>
                        Create another <i className='icon-refresh'></i>
                    </button>
                </div>
            </div>
        </CardStep>)
}

export default CompleteStep;