import { useEffect, useState } from "react";

import CardStep from "../../../components/CardStep";
import OptionsFormat from "../../../components/OptionsPaperSize";

import { config } from '../../../Config.js';

const FormatStep = ({ stepNumber, currentStep, format, onSelect }) => {
    const [_format, setFormat] = useState(format);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (format !== _format) {
            setFormat(format);
        }
    }, [format]);

    const onNext = () => {
        onSelect(_format);
    };

    const onFormatSelect = (format) => {
        setFormat(format);
        setShowError(false);
    };

    return (
        <CardStep
            stepNum={stepNumber}
            currentStep={currentStep}
            onPrev={false}
            onNext={onNext}
            nextEnabled={_format != null}
            title={currentStep > 1 ? 'Choose a format: ' + _format?.name : 'Choose a format'}
        >
            <OptionsFormat formats={config.Formats} selectedFormat={_format} onSelect={onFormatSelect} />
            {showError && <p className='error-msg'>Please choose a format</p>}
        </CardStep>
    )
};

export default FormatStep;