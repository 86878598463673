export const content = {
    Heading: 'Templates',
    Title: 'Template library',
    Filters:{
        Title: 'Format'
    },
    CreateButtonText: 'Create a new template',
    UploadButtonText: 'Upload a new template',
    Error: 'Templates could not be loaded, please try again later.',
    ErrorDelete: 'There was a problem deleting your template. This may be because it is already being used.',
    ConfirmDeleteHeading: 'Confirm Delete',
    ConfirmDeleteText: <p>Are you sure you want to delete this template?</p>,
    DeleteThisTemplateText: <><i className='icon-trash-empty'></i> Delete this template</>,
    // TODO: the edit icon doesn't work
    EditThisTemplateText: <><i className='icon-edit'></i> Edit this template</>,
    UseThisTemplateText: <><i className='icon-plus'></i> Use this template</>,
    Notification:{
      Deleted: 'Template deleted',
      Updated: 'Template updated',
      ProblemSaving: 'There was a problem saving your template. Please try again later.',
    },
    LoadMore: 'Load More',
}
