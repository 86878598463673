import { config } from '../Config';
import { useApiDelete, useApiGetBlob, useApiGetJson, useApiPatch, useApiPostFormJson } from './client-hook';

const baseUri = config.Templates.BaseUri;

export const useGetTemplatesV2 = (page, quantity, filters, send) => {
  const formats = filters == null ? '' : filters.filter(x => x.type === 'format').map(x => x.value).join(',');
  const published = filters == null ? 'true' : filters.filter(x => x.type === 'other' && x.value === 'unpublished').length > 0 ? 'false' : 'true';

  const url = send == false ? null : `${baseUri}?page=${page}&qty=${quantity}&format=${formats}&published=${published}`;
  return useApiGetJson(url, false);
};

export const useChangeTemplateNameV2 = (templateId, name, send) => {
  const url = send == null || send === false ? null : `${baseUri}${templateId}`;
  return useApiPatch(url, [
    {
      op: 'replace',
      path: 'name',
      value: name,
    },
  ]);
};

export const useGetTemplateTagsV2 = (templateId, send) => {
  const url = send == false ? null : `${baseUri}${templateId}/tags`;
  return useApiGetJson(url, true);
};

export const useUploadTemplateV2 = (file, name, format, send) => {
  const url = send === true ? baseUri : null;
  const formData = () => {
    const form = new FormData();
    form.append('file', file);
    form.append('name', name);
    form.append('format', format);
    return form;
  };

  return useApiPostFormJson(url, url === null ? null : formData(), 20000);
};

export const useNameCheckV2 = (name, send) => {
  const url = send == null || name == null || name.length === '' || name === 'Untitled' ? null : `${baseUri}name-check/${name}`;
  return useApiGetJson(url);
}

export const useDeleteTemplateV2 = (templateId, send) => {
  const url = send ? `${baseUri}${templateId}` : null;
  return useApiDelete(url);
}
