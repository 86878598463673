import { useEffect, useState } from 'react';
import { config } from '../../../Config.js';
import { content } from '../Templates.content.js';

const Filters = ({ onFilter }) => {
    const [_filters, setFilters] = useState([]);
    const formats = config.Formats;

    const filter = (event) => {
        var filters = [..._filters];

        const filter = { type: event.target.dataset.type, value: event.target.value };
        if (event.target.checked) {
            filters = [..._filters, filter];
        } else {
            const index = _filters.map(f => f.value).indexOf(filter.value);
            filters.splice(index, 1);
        }
        setFilters(filters);
    }

    const clear = () => {
        setFilters([]);
        document.querySelectorAll('input.template-filter').forEach((el) => (el.checked = false));
    }

    useEffect(() => {
        onFilter(_filters);
    }, [_filters]);

    return (
        <>
            <div className='col-filters'>
                <h2>{content.Title}</h2>
                <h4>{content.Filters.Title}</h4>
                <hr />
                <div className='filters'>
                    {formats.map((item, i) => {
                        return (
                            <div key={item.id} className='filter'>
                                <label htmlFor={'format-' + i}>
                                    <span>{item.name}</span>{' '}
                                    <input type='checkbox' className='template-filter' data-type='format' value={item.id} id={'format-' + i} onChange={filter} />
                                </label>
                            </div>
                        );
                    })}
                    {_filters.filter(x => x.type === 'format').length > 0 && (
                        <div className='link text-right'>
                            <button className='link' onClick={clear}>
                                Clear all
                            </button>
                        </div>
                    )}
                </div>
                <hr />
                <div className='filters'>
                    <div className='filter'>
                        <label htmlFor='unpublished'>
                            <span>Unpublished</span>
                            <input type='checkbox' className='template-filter' data-type='other' value='unpublished' id='unpublished' onChange={filter} />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Filters;