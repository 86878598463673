import { useEffect, useState } from "react";

import EditName from "../../components/EditName";
import ModalWrap from "../../components/ModalWrap";

import { useNameCheckV2 } from '../../clients/TemplatesClient.js';

const NameComponent = ({ name, onNameChanged }) => {
    const [_name, setName] = useState(name);
    const [showModal, setShowModal] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [makeNameCheck, setMakeNameCheck] = useState(false);

    const nameCheckResponse = useNameCheckV2(_name, makeNameCheck);

    useEffect(() => {
        if (name !== _name) {
            setName(name);
            setShowModal(true);
        }
    }, [name]);

    useEffect(() => {
        if (nameCheckResponse.status === 400) {
            setErrorMessage('Name is already in use. Please choose another');
            setIsError(true);
        } else {
            setErrorMessage('');
            setIsError(false);
        }
        setMakeNameCheck(false);
    }, [nameCheckResponse]);

    const onNameChange = (ev) => {
        const newName = ev.target.value;
        setName(_ => {
            const isEmpty = newName !== '' && newName !== null;
            if (isEmpty) {
                setErrorMessage('Name cannot be empty');
            } else {
                setMakeNameCheck(true);
            }
            setIsError(!isEmpty);
            return newName;
        });
    };

    const onNameUpdated = () => {
        onNameChanged(_name);
    };

    const closeModal = () => {
        if (_name !== '' && _name !== null) {
            setShowModal(false);

            if (isError === false) {
                onNameUpdated();
            } else {
                setShowModal(true);
            }

        } else {
            setErrorMessage('Name cannot be empty');
            setIsError(true);
        }
    }

    return (
        <>
            <EditName name={_name} callback={onNameUpdated} />

            <ModalWrap heading='Choose a name' show={showModal} onHide={closeModal}>
                <div className='form-row'>
                    <input
                        type='text'
                        id='input-template-name'
                        placeholder='Enter template name'
                        className={isError ? 'error' : ''}
                        onChange={onNameChange}
                    />
                    {isError && <div className='error-msg'>{errorMessage}</div>}
                </div>
                <div className='btn-wrap text-right'>
                    <button className='btn btn-solid' onClick={closeModal}>
                        Continue
                    </button>
                </div>
            </ModalWrap>
        </>
    );
}

export default NameComponent;