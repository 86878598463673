import { useEffect, useState, useContext } from 'react';

import { useChangeTemplateNameV2 } from '../../../clients/TemplatesClient.js';

import mainContext from '../../../context/context.js';
import { content } from '../Templates.content.js';

const EditTemplateName = ({ template, onSuccess }) => {
	const { notification } = useContext(mainContext);
	useEffect(() => {
		setName(template.name);
	}, [template]);

	const [_name, setName] = useState(template.name);
	const [_editing, setIsEditing] = useState(false);
	const [_save, setSave] = useState(false);

	const nameChangeResponse = useChangeTemplateNameV2(template.id, _name, _save);

	const onEditMode = () => {
		setIsEditing(true);
	}

	const nameUpdated = (event) => {
		setName(event.target.value);
	}

	const save = () => {
		if (_name !== template.name) {
			setSave(true);
		}
		else {
			setIsEditing(false);
		}
	}

	useEffect(() => {
		if (!nameChangeResponse.loading && nameChangeResponse.success) {
			notification({ message: content.Notification.Updated, type: 'positive' });
			setIsEditing(false);
			onSuccess(_name);
		}

		if (!nameChangeResponse.loading && nameChangeResponse.error) {
			if (nameChangeResponse.status === 400) {
				notification({ message: nameChangeResponse.error.Name[0], type: 'negative' });
			} else {
				notification({ message: content.Notification.ProblemSaving, type: 'negative' });
			}
		}

		setSave(false);
	}, [nameChangeResponse]);

	return (
		<div className="edit-name">
			<h2>
				{!_editing
					? _name
					: <input type="text" value={_name} onChange={nameUpdated} disabled={_save} />
				}
			</h2>
			{!_editing
				? <button className="link" onClick={onEditMode}>Edit name</button>
				: <button className={nameChangeResponse.loading ? 'link is-spinning' : 'link'} onClick={save} disabled={nameChangeResponse.loading}>Save name</button>
			}
		</div>
	)
}
export default EditTemplateName;
