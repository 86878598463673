import { useEffect, useState } from 'react';
import { useCreateTemplate, usePublishTemplate, useSaveTemplate } from '../../clients/TemplateEditorClient';

const ActionControls = ({ store, editorReadyData }) => {
  const [templateId, setTemplateId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [publishedVersion, setPublishedVersion] = useState(0);

  let enableSave = hasChanges;
  let enablePublish = !enableSave && currentVersion !== publishedVersion;

  const createTemplateMutation = useCreateTemplate('the name', editorReadyData?.format, store.toJSON(), async (data) => {
    setTemplateId(data.id);
    setCurrentVersion(data.version);
    setHasChanges(false);

    // TODO: add toast notification
  });

  const saveTemplateMutation = useSaveTemplate(templateId, 'the name', editorReadyData?.format, store.toJSON(), async (data) => {
    setCurrentVersion(data.version);
    setHasChanges(false);

    // TODO: add toast notification
  });

  const publishTemplateMutation = usePublishTemplate(templateId, currentVersion, async () => {
    setPublishedVersion(currentVersion);

    // TODO: add toast notification
  });

  useEffect(() => {
    if (editorReadyData === null) {
      return;
    }

    setTemplateId(editorReadyData.templateId);
    setCurrentVersion(editorReadyData.currentVersion);
    setPublishedVersion(editorReadyData.currentVersion);
  }, [editorReadyData])

  store.on('change', () => {
    if (editorReadyData === null) {
      // first time this fires it uses cached data so ignore it
      return;
    }

    setHasChanges(true);
  });

  const save = async () => {
    if (templateId !== null) {
      await saveTemplateMutation.mutateAsync();
    } else {
      await createTemplateMutation.mutateAsync();
    }
  };

  const publish = async () => {
    await publishTemplateMutation.mutateAsync();
  };

  const saveIcon = () => {
    const className = saveTemplateMutation.isPending || createTemplateMutation.isPending ? 'icon-spin6 animate-spin' : 'icon-floppy';
    return (<i className={className}></i>)
  }

  const publishIcon = () => {
    const className = publishTemplateMutation.isPending ? 'icon-spin6 animate-spin' : 'icon-upload';
    return (<i className={className}></i>)
  }

  return (
    <>
      <div>{currentVersion}&nbsp;:&nbsp;{publishedVersion}</div>
      <button className="btn btn-solid" id='save' onClick={save} disabled={!enableSave}>
        Save {saveIcon()}
      </button>
      &nbsp;
      <button className="btn btn-solid" id='publish' onClick={publish} disabled={!enablePublish}>
        Publish {publishIcon()}
      </button>
    </>
  );
};

export default ActionControls;