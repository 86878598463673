import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import GlobalDataManager from '../GlobalDataManager.js';

const RequireAuth = ({ children }) => {
  const userData = GlobalDataManager.getInstance().getUserData();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  let location = useLocation();

  if (isAuthenticated) {
    // Check if use can access integrations
    if (location.pathname === '/webhooks' && userData.subscription !== 'Advanced') {
      return <Navigate to='/' state={{ from: location }} />;
    } else {
      return children;
    }
  } else {
    loginWithRedirect();
    return null;
  }
};
export default RequireAuth;
