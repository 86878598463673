import { useState } from 'react';
import PageHeader from '../../components/PageHeader.js';
import Card from '../../components/Card.js';
import { config } from '../../Config.js';

import { content } from './content.js';
import NameComponent from './NameComponent.js';
import FormatStep from './Steps/FormatStep.js';
import UploadStep from './Steps/UploadStep.js';
import CompleteStep from './Steps/CompleteStep.js';

const TemplateUploadPage = () => {
  const acceptedTypes = {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
  };

  if (config.Toggles.EnablePdfs) {
    acceptedTypes['application/pdf'] = ['.pdf'];
  }

  const [_currentStep, setCurrentStep] = useState(1);

  const [_templateName, setTemplateName] = useState(null);
  const [format, setFormat] = useState(null);

  const prevStep = (step) => {
    setCurrentStep(step - 1);
  };

  const nextStep = () => {
    setCurrentStep(_currentStep + 1);
  };

  // useEffect(() => {
  //   // reset if error
  //   if (uploadResponse.error) {
  //     setFile(null);
  //     setIsFileSelected(false);
  //   }
  //   if (!uploadResponse.success) {
  //     return;
  //   }
  //   nextStep(_currentStep);
  // }, [_currentStep, uploadResponse]);

  const doReset = () => {
    setCurrentStep(1);
    setTemplateName(null)
    setFormat(null);

  //   // reset vars
  //   setCurrentStep(1);
  //   setIsComplete(false);
  //   setColor(false);
  //   setFile(null);
  //   setIsFileSelected(false);
  //   setIsNamed(false);
  //   setIsNamedError(false);
  //   setTemplateName('Untitled');
  //   setFormat(false);
  //   setErrors({});
  };

  const onNameUpdated = (name) => {
    console.log(name);
    setTemplateName(name);
  };

  const onFormatSelect = (format) => {
    setFormat(format);
    nextStep();
  };

  const onFileSelected = () => {
    nextStep();
  }

  return (
    <>
      <PageHeader pageTitle={content.Heading} />

      <section className='gp-section gp-section--ct-wizard'>
        <div className='gp-section__inner'>
          <div className='gp-section__heading'>
            <NameComponent name={_templateName} onNameChanged={onNameUpdated} />
          </div>

          <div className='flex'>
            <div className='col-6'>
              <FormatStep 
                stepNumber={1} 
                currentStep={_currentStep} 
                format={format}
                onSelect={onFormatSelect} />

              <UploadStep 
                stepNumber={2} 
                currentStep={_currentStep} 
                name={_templateName}
                format={format}
                onPrev={prevStep} 
                title='Upload your template' 
                onFileUploaded={onFileSelected}
                />

              <CompleteStep stepNumber={3} currentStep={_currentStep} uploadAnother={doReset} />

              <Card className='card--getting-started'>
                <div className='help-rows'>
                  {content.HelpArticles.map((item, i) => {
                    return (
                      <div key={item.id} className='help-row'>
                        <div className='flex'>
                          <div className='col-icon'>
                            <div className='icon'>
                              <i className={item.icon}></i>
                            </div>
                          </div>
                          <div className='col'>
                            <div className='help-row__title'>{item.title}</div>
                            <div className='help-row__excerpt'>{item.excerpt}</div>
                            <a href={item.url} className='help-row__link' target='_blank'>
                              Learn how <i className='icon-angle-right'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default TemplateUploadPage;
