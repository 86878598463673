const CardStep = ({ stepNum, currentStep, onPrev, onNext, onPrevLbl, onNextLbl, nextEnabled, title, children, stepClass = '' }) => {

  const state = currentStep === stepNum ? 'active' : currentStep < stepNum ? 'locked' : 'complete';
  const prevLbl = (onPrevLbl) ? onPrevLbl : <><i className="icon-arrow-left"></i> Previous</>;
  const nextLbl = (onNextLbl) ? onNextLbl : <>Next <i className="icon-arrow-right"></i></>;
  const nextClass = (!nextEnabled) ? ' disabled' : '';

  return (
    <div className={"card card--step card--" + state + ' ' + stepClass}>
      <div className="card__inner">
        <div className="card-divider">
          <div className="flex">
            <div className="col">
              <span className="step-num">{stepNum}</span><span className="card-title">{title}</span>
            </div>
            <div className="col text-right">
              <span className="step-check"><i className="icon-check"></i></span>
            </div>
          </div>
        </div>
        <div className="card-section">
          {children}
          <div className="flex nav-btns">
            <div className="col-6">
              {
                onPrev && (
                  <button className="btn btn-outline btn-prev" onClick={() => onPrev(stepNum)}>{prevLbl}</button>
                )
              }
            </div>
            <div className="col-6 text-right">
              {
                onNext && (
                  <button className={"btn btn-solid btn-next" + nextClass} onClick={() => onNext(stepNum)}>{nextLbl}</button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CardStep;
