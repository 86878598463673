import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner.js';
import PageHeader from '../../components/PageHeader.js';
import TemplatePanel from '../../components/TemplatePanel.js';
import { useGetTemplatesV2 } from '../../clients/TemplatesClient.js';
import { content } from './Templates.content.js';
import Filters from './Components/Filters.js';

import { config } from '../../Config.js';
import ViewTemplate from './Components/ViewTemplateComponent.js';

const TemplatesPage = () => {
  const [_page, setPage] = useState(1);
  const perPage = (_page === 1) ? 10 : 12; // space for create new template btn

  const [_templateSelected, setTemplateSelected] = useState(null);

  const [_loadedTemplates, setLoadedTemplates] = useState([]);

  const [_fetchTemplates, setFetchTemplates] = useState(true);
  const [_filters, setFilters] = useState([]);

  const [_selectedTemplate, setSelectedTemplate] = useState(null);

  const templatesResponse = useGetTemplatesV2(_page, perPage, _filters, _fetchTemplates);

  useEffect(() => {
    if (!templatesResponse.loading && templatesResponse.success) {
      let templates = _loadedTemplates;
      templatesResponse.data.data.forEach((item, i) => {
        if (templates.findIndex(x => x.id === item.id) === -1) {
          templates.push(item);
        }
      });
      setLoadedTemplates([...templates]);
      setFetchTemplates(false);
    }
  }, [templatesResponse]);

  const loadMore = () => {
    setPage(_page + 1);
    setFetchTemplates(true);
  };

  const onTemplateNameUpdated = (name) => {
    _selectedTemplate.name = name;
  };

  const onSelectTemplate = (templateId) => {
    const items = _loadedTemplates.filter((t) => t.id === templateId);
    if (items.length === 0) {
      return;
    }

    setSelectedTemplate(items[0]);
  };

  const onFilter = (filters) => {
    setFilters(filters);
    setPage(1);
    setLoadedTemplates([]);
    setFetchTemplates(true);
  };

  const onDeleted = () => {
    setLoadedTemplates(_loadedTemplates.filter((t) => t.id !== _selectedTemplate.id));
    setTemplateSelected(null);
  };

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--ct-templates'>
        <div className='gp-section__inner'>

          <div className='flex'>

            <Filters onFilter={onFilter}></Filters>

            <div className='col-main'>
              {
                <div className='templates'>
                  {(config.Toggles.EnableTemplateEditor) ?
                    <div className={'template-panel template-panel--new col-20'}>
                      <Link to='/templates/editor' className='template-panel__inner'>
                        <div className='template-icon'>
                          <div className='image-wrap'>
                            <div className='icon-button'>
                              <i className='icon-plus-1'></i>
                            </div>
                          </div>
                        </div>
                        <div className='template-meta'>
                          <div className='template-name'>{content.CreateButtonText}</div>
                        </div>
                      </Link>
                    </div>
                    : <></>}
                  <div className={'template-panel template-panel--new col-20'}>
                    <Link to='/upload-template' className='template-panel__inner'>
                      <div className='template-icon'>
                        <div className='image-wrap'>
                          <div className='icon-button'>
                            <i className='icon-upload'></i>
                          </div>
                        </div>
                      </div>
                      <div className='template-meta'>
                        <div className='template-name'>{content.UploadButtonText}</div>
                      </div>
                    </Link>
                  </div>
                  {_loadedTemplates.map((item, _) => {
                    return <TemplatePanel key={item.id} template={item} onClick={onSelectTemplate} />;
                  })}
                </div>
              }
              {templatesResponse.loading && <Spinner />}
              {templatesResponse.error !== null && <p className='alert-msg text-center'>{content.Error}</p>}


              {templatesResponse.data.hasMorePages ? (
                <div className='btn-wrap btn-wrap--centered'>
                  <button
                    className={templatesResponse.loading ? 'btn btn-solid is-spinning' : 'btn btn-solid'}
                    onClick={loadMore}
                    disabled={templatesResponse.data.loading}
                  >
                    {content.LoadMore}
                  </button>
                </div>
              ) : null}
            </div>
          </div>

        </div>
      </section>

      <ViewTemplate template={_selectedTemplate} onHide={() => setSelectedTemplate(null)} onDeleted={onDeleted} onRename={onTemplateNameUpdated} />
    </>
  );
};

export default TemplatesPage;
