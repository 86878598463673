import { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import mainContext from '../../../context/context.js';

import { content } from '../Templates.content.js';

import EditTemplateName from './EditTemplateName.js';
import ModalWrap from '../../../components/ModalWrap.js';
import TemplateSlider from '../../../components/TemplateSlider.js';

import { useDeleteTemplateV2 } from '../../../clients/TemplatesClient.js';

const ViewTemplate = ({ template, onHide, onDeleted, onRename }) => {
    const { notification } = useContext(mainContext);

    useEffect(() => {
        setInfoModalVisible(template !== null);
    }, [template]);

    const [infoModalVisible, setInfoModalVisible] = useState(template !== null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [send, setSend] = useState(false);
    const [_deleteError, setDeleteError] = useState(null);

    const deleteResponse = useDeleteTemplateV2(template === null ? null : template.id, send);

    useEffect(() => {
        if (deleteResponse.success) {
            // reset delete method
            setSend(false);
            setDeleteModalVisible(false);
            notification({ message: content.Notification.Deleted, type: 'positive' });
            onDeleted();
        } else if (deleteResponse.error !== null) {
            // display error
            setDeleteError(content.ErrorDelete);
        }
    }, [deleteResponse]);


    const switchModals = () => {
        setInfoModalVisible(!infoModalVisible);
        setDeleteModalVisible(!deleteModalVisible);
    }

    return (
        template !== null && <>
            <ModalWrap heading='Template' show={infoModalVisible} staticBG={true} onHide={onHide}>
                <EditTemplateName template={template} id={template.id} name={template.name} label='title' onSuccess={onRename} />
                <TemplateSlider template={template} />
                <div className='btn-wrap text-center'>
                    {/*
                    // TODO: while this does link correctly, the wizard doesn't handle it yet
                    <Link to={'/create-mailing/' + template.id} className='btn btn-solid'>
                        {content.UseThisTemplateText}
                    </Link> */}
                    {template.source.toLowerCase() === 'editor' &&
                        <Link to={'/templates/editor/' + template.id} className='btn btn-solid'>{content.EditThisTemplateText}</Link>
                    }
                    <button className='btn btn-delete' onClick={switchModals}>
                        {content.DeleteThisTemplateText}
                    </button>
                </div>
            </ModalWrap>

            <ModalWrap heading={content.ConfirmDeleteHeading} show={deleteModalVisible} onHide={switchModals}>
                {content.ConfirmDeleteText}
                {_deleteError && <p className='alert-msg'>{_deleteError}</p>}
                <div className='btn-wrap text-right'>
                    <button className='btn btn-outline' onClick={switchModals}>
                        Cancel
                    </button>
                    <button className='btn btn-solid btn-delete' onClick={() => setSend(true)}>
                        Delete
                    </button>
                </div>
            </ModalWrap>

        </>
    )
}

export default ViewTemplate;