import { useEffect, useState } from 'react';
import EditName from '../../components/EditName.js';
import ModalWrap from '../../components/ModalWrap.js';

const NameComponent = ({ wizardId, onNameChanged }) => {
    const [name, setName] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setName(null);
        setShowModal(true);
    }, [wizardId]);

    const onNameChange = (ev) => {
        const newName = ev.target.value;
        setName(_ => {
            const isValid = newName !== '' && newName !== null;
            setIsError(!isValid);
            return newName;
        });
    };

    const onNameUpdated = () => {
        onNameChanged(name);
    };

    const closeModal = () => {
        if (name !== '' && name !== null) {
            setShowModal(false);
            onNameChanged(name);
        } else {
            setIsError(true);
        }
    }

    return (
        <>
            <EditName name={name} callback={onNameUpdated} />

            <ModalWrap heading='Choose a name' show={showModal} onHide={closeModal}>
                <div className='form-row'>
                    <input
                        type='text'
                        id='input-mailing-name'
                        placeholder='Enter mailing name'
                        className={isError ? 'error' : ''}
                        onChange={onNameChange}
                    />
                    {isError && <div className='error-msg'>Name cannot be empty</div>}
                </div>
                <div className='btn-wrap text-right'>
                    <button className='btn btn-solid' onClick={closeModal}>
                        Continue
                    </button>
                </div>
            </ModalWrap>
        </>
    )
}

export default NameComponent;