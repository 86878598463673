import { config } from '../Config';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@tanstack/react-query';


export const useGetLatestTemplateVersion = (templateId, enable) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: ['getEditorTemplate', templateId],
        enabled: enable,
        queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            let response = await fetch(`${config.Templates.BaseUri}editor/${templateId}/latest`, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            return response.json();
        }
    });
}

export const useCreateTemplate = (name, format, content, onSuccess) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation({
        queryKey: ['createEditorTemplate'],
        mutationFn: async () => {
            var accessToken = await getAccessTokenSilently();
            let response = await fetch(`${config.Templates.BaseUri}editor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify({
                    name: name,
                    format: format.id,
                    content: JSON.stringify(content)
                })
            });
            return response.json();
        },
        onSuccess: onSuccess
    });
}


export const useSaveTemplate = (templateId, name, format, content, onSuccess) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation({
        queryKey: ['saveEditorTemplate', templateId],
        mutationFn: async () => {
            const accessToken = await getAccessTokenSilently();
            let response = await fetch(`${config.Templates.BaseUri}editor/${templateId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify({
                    name: name,
                    format: format.id,
                    content: JSON.stringify(content)
                })
            });

            return response.json();
        },
        onSuccess: onSuccess
    });
}

export const usePublishTemplate = (templateId, version, onSuccess) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation({
        queryKey: ['publishEditorTemplate', templateId],
        mutationFn: async () => {
            const accessToken = await getAccessTokenSilently();
            await fetch(`${config.Templates.BaseUri}editor/${templateId}/publish`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify({
                    version: version
                })
            });
        },
        onSuccess: onSuccess
    });
}